export default class Project {
  constructor() {
    this.projectTextDisplay();
  }

  projectTextDisplay() {
    if (window.matchMedia("(max-width: 576px)").matches) {
      const project = document.querySelector(".content__col--right .content__col__inner");
      const title = document.querySelector(".project__title");
      if (project) {
        title.addEventListener("click", () => {
          project.classList.toggle("open");
        });
      }
    }
  }
}
