import Flickity from "flickity";

export default class Slideshow {
  constructor() {
    this.carousel = null;
    this.clickImage();
  }

  initSlideshow(slideshow) {
    var slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "left",
      setGallerySize: false,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: true,
      selectedAttraction: 0.1,
      friction: 0.6,
      lazyLoad: true,
    };

    this.initFlickity(slideshow, slideOptions);
    $(slideshow)
      .off("mousemove")
      .on("mousemove", (e) => {
        if (this.checkPosition(slideshow, e)) {
          $(e.currentTarget).removeClass("w-cursor");
        } else {
          $(e.currentTarget).addClass("w-cursor");
        }
      });
  }

  initFlickity(element, options) {
    this.carousel = new Flickity($(element)[0], options);
    const arrowNext = $(element).parent().find(".js-slideshowNext");
    const arrowPrev = $(element).parent().find(".js-slideshowPrev");
    const close = $(element).parent().find(".js-lightboxClose");
    const counter = $(element).parent().find(".js-slideshowCount");

    $(arrowNext).on("click", () => {
      this.carousel.next(true);
    });

    $(arrowPrev).on("click", () => {
      this.carousel.previous(true);
    });

    this.carousel.on("staticClick", (e, pointer) => {
      if (!$(e.target).hasClass("js-controlVideo") && !$(e.target).parents().hasClass("js-controlVideo")) {
        if (this.checkPosition(element, pointer)) {
          this.carousel.next(true);
        } else {
          this.carousel.previous(true);
        }
      }
    });

    this.carousel.on("change", (index) => {
      const i = index + 1;
      $(counter).text(i);
      $(element)
        .find(".js-video")
        .each((i, el) => {
          $(el)[0].pause();
          $(el).siblings().find(".js-pauseVideo").addClass("hidden").siblings().removeClass("hidden");
        });
    });

    $(close).on("click", () => {
      this.closeSlideshow(element);
    });
  }

  positionSlideshow(index) {
    this.carousel.select(index, true, true);
  }

  closeSlideshow(element) {
    const container = $(element).parent();
    $(container).animate({ opacity: 0 }, 300, () => {
      $(container).removeClass("active");
    });
  }

  openSlideshow(index = 0, item) {
    const container = $(".js-lightboxContainer");
    const slideshow = $(container).find(".js-slideshow");
    $(container).css({ opacity: 0 }).addClass("active");
    if (!this.carousel) this.initSlideshow(slideshow);
    this.positionSlideshow(index);
    $(container).animate({ opacity: 1 }, 300);
  }

  clickImage() {
    $(".js-gridItem").on("click", (e) => {
      if (!$(e.currentTarget).children().first().is("a")) {
        const item = $(e.currentTarget);
        const items = $(item).parents(".js-grid").find(".js-gridItem");
        const index = $(items).index(item);
        this.openSlideshow(index, item);
      }
    });
  }

  checkPosition(el, pointer) {
    const windowWidth = $(el).outerWidth();
    const percentage = pointer.pageX / windowWidth;
    const x = Math.round(percentage);
    return x;
  }
}
