import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Home from "./_modules/home";
import Lightbox from "./_modules/lightbox";
import Video from "./_modules/video";
import Project from "./_modules/project";
import MenuMobile from "./_modules/menu__mobile";
import Captions from "./_modules/captions";

$(function () {
  new Website();
  new Home();
  new Lightbox();
  new Video();
  new Project();
  new MenuMobile();
  new Captions();
});
