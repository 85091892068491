export default class MenuMobile {
  constructor() {
    this.menuSubDisplay();
  }

  menuSubDisplay() {
    if (window.matchMedia("(max-width: 576px)").matches) {
      const menu = document.querySelector(".menu--mobile");
      document.querySelectorAll(".menu__title").forEach((el) => {
        el.addEventListener("click", (e) => {
          if (document.documentElement.classList.contains("touch")) e.preventDefault();
          Array.from(document.querySelectorAll(".menu__title"))
            .filter((item) => item != el)
            .forEach((el) => el.classList.remove("open"));
          el.classList.toggle("open");
        });
      });
    }
  }
}
