export default class Video {
  constructor() {
    this.pauseTimeout = null;
    this.initVideoPlayer();
  }

  initVideoPlayer() {
    $(".js-video").each((i, el) => {
      $(el)
        .siblings()
        .find(".js-playVideo")
        .on("click", (e) => {
          const videoElement = $(el)[0];
          this.playVideo(videoElement, e.currentTarget);
        });

      $(el)
        .siblings()
        .find(".js-pauseVideo")
        .on("click", (e) => {
          const videoElement = $(el)[0];
          this.pauseVideo(videoElement, e.currentTarget);
        });

      $(el).on("ended", (e) => {
        $(el).siblings().find(".js-pauseVideo").addClass("hidden").siblings().removeClass("hidden");
      });

      $(el).on("mousemove", () => {
        const pauseButton = $(el).siblings().find(".js-pauseVideo");
        if (!$(pauseButton).hasClass("hidden")) {
          clearTimeout(this.pauseTimeout);
          $(pauseButton).removeClass("opacity");
          this.pauseTimeout = setTimeout(() => {
            $(pauseButton).addClass("opacity");
          }, 1000);
        }
      });
    });
  }

  pauseVideo(videoElement, button) {
    videoElement.pause();
    $(button).addClass("hidden").siblings().removeClass("hidden");
    clearTimeout(this.pauseTimeout);
  }

  playVideo(videoElement, button) {
    const pauseButton = $(button).siblings(".js-pauseVideo");
    videoElement.play();
    $(button).addClass("hidden").siblings().removeClass("hidden");
    setTimeout(() => {
      $(pauseButton).addClass("opacity");
    }, 1000);
  }
}
